import React from 'react';
import logo from '../assets/img/logo.png';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons/faHeart';
import config from "../../config";

library.add(...[faHeart]);
export default function Footer() {
  return (
    <footer className="bg-fg small text-center text-white-50">
      <div className="container">
        <div className="row">
          <div className="col text-center">
            <a
              href="https://www.emmim.pt/"
              title="Página inicial"
              target="_blank"
            >
              <img src={logo} className="logo" alt={`EMMIM Logo`} />
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col text-center p-3">
            <a
              title="Cookies"
              style={{ color: "white", opacity: "0.8", marginLeft: "16px" }}
              href={`${config.url}${config.pathPrefix}cookies/`}
            >
              {" "}
              Cookies{" "}
            </a>
            <a
              style={{ color: "white", opacity: "0.8", marginLeft: "16px" }}
              title="Politica Privacidade"
              href={`${config.url}${config.pathPrefix}privacidade/`}
            >
              {" "}
              Politica Privacidade{" "}
            </a>
            <a
              style={{ color: "white", opacity: "0.8", marginLeft: "16px" }}
              title="termos"
              href={`${config.url}${config.pathPrefix}termos/`}
            >
              {" "}
              Termos{" "}
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col text-center p-3">
            
            <a
              title="Parcerias"
              style={{ color: "white", opacity: "0.8", marginLeft: "16px" }}
              href={`${config.url}${config.pathPrefix}parcerias/`}
            >
              {" "}
              Parcerias{" "}
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col text-center p-5">
            Made with
            <FontAwesomeIcon icon={"heart"} /> by
            <a
              title="EMMIM - Coworking de terapeutas"
              href="https://www.emmim.pt/"
            >
              EMMIM
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
